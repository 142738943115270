<template>
  <footer class="main-footer-container">
    <div class="footer-container">
      <div class="footer-description-section">
        <div class="first-Column">
          <span class="coloum-topic">{{ $t("footer.Services") }}</span>
          <div>
            <dl class="lists">
              <dt class="list-item"><a href="https://play.google.com/store/apps/details?id=com.easypay.ican" class="link" target="_blank" >{{ $t("footer.ICanMeta") }}</a></dt>
              <dt class="list-item"><a href="https://icanchat.app/" class="link" target="_blank"> {{ $t("footer.ICanChat") }}</a></dt>
              <dt class="list-item"><a href="https://properties.icanlk.com/" class="link" target="_blank"> {{ $t("footer.Properties") }}</a></dt>
            </dl>
          </div>
        </div>
        <div class="customer-service">
          <span class="coloum-topic">{{ $t("footer.Policy") }}</span>
          <div>
            <dl class="lists">
              <dt v-for="(policy,index) in policies" :key="index" class="list-item" @click="redirectToPolicies(policy)" >{{ policy.title }}</dt>
            </dl>
          </div>
        </div>
        <div class="second-column">
          <span class="coloum-topic">{{ $t("footer.Help") }}</span>
          <dl class="lists">
            <dt class="list-item"><a href="https://icanlk.com/" class="link" target="_blank">About</a></dt>
            <dt class="list-item" @click="this.$router.push({name: 'ContactUs'})">{{ $t("footer.Contact") }}</dt>
          </dl>
        </div>
        <div class="third-column">
          <span class="coloum-topic">{{ $t("footer.SocilaMedia") }}</span>
          <div class="icons-set">
            <a href="https://www.facebook.com/profile.php?id=61558647865101" class="link" target="_blank"><svg-icon :icon="FbIcon" class="icon"></svg-icon></a>
            <a href="https://twitter.com/icanmeta" class="link" target="_blank"><svg-icon :icon="TwiterIcon" class="icon"></svg-icon></a>
            <a href="https://www.instagram.com/icanmall/" class="link" target="_blank"><svg-icon :icon="Instar" class="icon"></svg-icon></a>
            <a href="https://www.youtube.com/@iCanMallSL" class="link" target="_blank"><svg-icon :icon="Youtube" class="icon"></svg-icon></a>
            <a href="https://www.linkedin.com/company/ican-lanka-pvt-ltd/mycompany/verification/" class="link" target="_blank"><svg-icon :icon="LinkedinIcon" class="icon"></svg-icon></a>
          </div>
        </div>
        <div class="fourth-column">
          <span class="coloum-topic">{{ $t("footer.SaveApp") }}</span>
          <div class="app-details-qr">
            <div class="qr-and-logo-section">
              <div class="qr-image">
                <img :src="qrImage" alt="" class="qr-image" loading="lazy">
              </div>
            </div>
            <div class="apps">
              <a href="https://apps.apple.com/lk/app/ican-mall/id1542182604" class="link" target="_blank"><img :src="AppstoreImg" alt="" class="app-store-img"></a>
              <a href="https://play.google.com/store/apps/details?id=com.easypay.shop&hl=en&gl=US" class="link" target="_blank"><img :src="PlaystoreImg" alt="" class="app-store-img"></a>
              <a href="https://play.google.com/store/apps/details?id=com.easypay.shop&hl=en&gl=US" target="_blank"><img :src="AppGallery" alt="" class="app-store-img"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="last-col-mob">
        <div class="mob-first">
          <span class="coloum-topic">{{ $t("footer.Help") }}</span>
          <dl class="lists">
            <dt class="list-item"><a href="https://icanlk.com/" class="link" target="_blank">{{ $t("footer.AboutUs") }}</a></dt>
            <dt class="list-item" @click="this.$router.push({name: 'ContactUs'})">{{ $t("footer.Contact") }}</dt>
          </dl>
        </div>
        <div class="mob-second">
          <span class="coloum-topic">{{ $t("footer.SaveApp") }}</span>
          <div class="qr-and-logo-section-mob">
            <div class="qr-image-mob">
              <img :src="qrImage" alt="" class="qr-image-mob" loading="lazy">
            </div>
            <div class="apps-mob">
              <a href="https://apps.apple.com/lk/app/ican-mall/id1542182604" target="_blank"><img :src="AppstoreImg" alt="" class="app-store-img-mob"></a>
              <a href="https://play.google.com/store/apps/details?id=com.easypay.shop&hl=en&gl=US" target="_blank"><img :src="PlaystoreImg" alt="" class="app-store-img-mob"></a>
              <a href="https://play.google.com/store/apps/details?id=com.easypay.shop&hl=en&gl=US" target="_blank"><img :src="AppGallery" alt="" class="app-store-img-mob"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="social-icon-on-tab-mobile">
        <span class="coloum-topic">{{ $t("footer.SocilaMedia") }}</span>
        <div class="social-icon-set">
          <a href="https://www.facebook.com/profile.php?id=61558647865101" class="link" target="_blank"><svg-icon :icon="FbIcon" class="icon"></svg-icon></a>
          <a href="https://twitter.com/icanmeta" class="link" target="_blank"><svg-icon :icon="TwiterIcon" class="icon"></svg-icon></a>
          <a href="https://www.instagram.com/icanmall/" class="link" target="_blank"><svg-icon :icon="Instar" class="icon"></svg-icon></a>
          <a href="https://www.youtube.com/@iCanMallSL" class="link" target="_blank"><svg-icon :icon="Youtube" class="icon"></svg-icon></a>
          <a href="https://www.linkedin.com/company/ican-lanka-pvt-ltd/mycompany/verification/" class="link" target="_blank"><svg-icon :icon="LinkedinIcon" class="icon"></svg-icon></a>
        </div>
      </div>
    </div>
    <div class="reserved-container">
      <span>&#169; {{ new Date().getFullYear() }} {{ $t('headerTitle.Mall') }}. {{ $t('Label.CopyRight') }}</span>
    </div>
  </footer>
</template>

<script>
import FbIcon from '@/assets/icons/svg/fb.svg'
import TwiterIcon from '@/assets/icons/svg/sky.svg'
import LinkedinIcon from '@/assets/icons/svg/instar.svg'
import Youtube from '@/assets/icons/svg/youtube.svg'
import AppGallery from '@/assets/icons/png/appgallery.png'
import Instar from '@/assets/icons/svg/instar.svg'
// import MallLogo from '@/assets/icons/png/footer-imall-logo.webp'
import qrImage from '@/assets/icons/png/footer-qr.webp'
import AppstoreImg from '@/assets/icons/png/footer-appstore.png'
import PlaystoreImg from '@/assets/icons/png/footer-playstore.png'
import {defineAsyncComponent} from "vue";


const SvgIcon = defineAsyncComponent(() => import("@/components/SvgIcon/SvgIcon"))

// import {getDownloadApp} from "@/api/appfooter"
export default {
  name: "AppFooter",
  created() {
    // this.getDownloadAppLink();
  },
  data() {
    return {
      FbIcon:FbIcon,
      TwiterIcon:TwiterIcon,
      LinkedinIcon:LinkedinIcon,
      Youtube:Youtube,
      Instar:Instar,
      // MallLogo:MallLogo,
      AppstoreImg:AppstoreImg,
      PlaystoreImg:PlaystoreImg,
      qrImage:qrImage,
      AppGallery:AppGallery,
      policies: [
        {
          title: this.$t("footer.ServiceAgreement"),
          link: "https://icanlk.com/static/agreement/html/ican_terms_and_services"
        },
        {
          title: this.$t("footer.PrivacyAgreement"),
          link: "https://icanlk.com/static/agreement/html/ican_mall_privacy_agreement"
        },
        {
          title: this.$t("footer.QRPay"),
          link: "https://icanlk.com/static/agreement/html/ican_refund_return_policy"
        }
      ]
    };
  },
  components:{ SvgIcon },
  methods: {
      redirectToPolicies({link}){
        let browserLang = this.$i18n.locale
        let query;
          if (browserLang == "zn"){
            query = "-language=zh-cn"
          }
          else{
            query = "-language=en-us"
          }
        window.open(link+query)
      }
    },
  };

</script>

<style scoped lang="sass">
@import "@/assets/styles/__footer.sass"
.lists
  cursor: pointer
</style>
